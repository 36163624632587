import { UppyAngularDashboardModule } from '@uppy/angular';
import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatDialogModule } from '@angular/material/dialog';
import { MatMenuModule } from '@angular/material/menu';
import { RouterModule } from '@angular/router';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { TranslateModule } from '@ngx-translate/core';
import { SimpleNotificationsModule } from 'angular2-notifications';
import { DateFormatPipe, MomentModule } from 'ngx-moment';
import { NgxPaginationModule } from 'ngx-pagination';
import { CustomersService } from '../customers/services/customers.service';
import { CustomersFiltersService } from '../customers/services/filters.service';
import { BreadcrumbComponent } from './components/breadcrumb/breadcrumb.component';
import { CardComponent } from './components/card/card.component';
import { CommentsComponent } from './components/comments/comments.component';
import { ClosableCardComponent } from './components/card/closable-card.component';
import { FileInputComponent } from './components/file-input/file-input.component';
// Components
import { FilterComponent } from './components/filter/filter.component';
import { HeroIconComponent } from './components/hero-icon/hero-icon.component';
import { ImageCropperComponent } from './components/image-cropper/image-cropper.component';
import { LoaderComponent } from './components/loader/loader.component';
import { ModalComponent } from './components/modal/modal.component';
import { PaginationComponent } from './components/pagination/pagination.component';
import { ScheduleComponent } from './components/schedule/schedule.component';
import { Schedulev2Component } from './components/schedulev2/schedulev2.component';
import { SearchModalComponent } from './components/search-modal/search-modal.component';
import { TableServerComponent } from './components/table-server/table-server.component';
import { TableComponent } from './components/table/table.component';
import { TabComponent } from './components/tabs/tab.component';
import { TabsComponent } from './components/tabs/tabs.component';
import { TooltipComponent } from './components/tooltip/tooltip.component';
import { MatSelectModule } from '@angular/material/select';
import { MultiSelectComponent } from './components/multi-select/multi-select.component';
// Directives
import { DefaultImageDirective } from './directives/default-img.directive';
import { HasPermissionDirective } from './directives/has-one-permission.directive';
import { NAV_DROPDOWN_DIRECTIVES } from './directives/nav-dropdown.directive';
import { ShowPasswordDirective } from './directives/show-password.directive';
// Guards
import { AuthGuard } from './guards/auth.guard';
import { CanDeactivateGuard } from './guards/can-deactivate.guard';
import { HomeGuard } from './guards/home.guard';
import { OneNetworkGuard } from './guards/one-network.guard';
import { PermissionGuard } from './guards/permission.guard';
// Pipes
import { HighlightPipe } from './pipes/highlight.pipe';
import { IntervalPipe } from './pipes/interval.pipe';
import { KeyPipe } from './pipes/key.pipe';
import { OrderByPipe } from './pipes/order-by.pipe';
import { SearchPipe } from './pipes/search.pipe';
import { ValuesPipe } from './pipes/values.pipe';
import { CurrencyPipe } from './pipes/currency.pipe';
import { SafeHtmlPipe } from './pipes/safeHtml.pipe';
// Resolvers
import { EmailsNetworkResolver } from './resolvers/emails.resolvers';
import { LinesResolver } from './resolvers/lines.resolver';
import { RolesResolver } from './resolvers/roles.resolver';
import { TagsMailNetworkResolver } from './resolvers/tags-mail.resolver';
// Services
import { CacheBustingService } from './services/cache-busting.service';
import { ColorService } from './services/color.service';
import { ExportService } from './services/export.service';
import { FeedService } from './services/feed.service';
import { FilterService } from './services/filter.service';
import { LinesService } from './services/lines.service';
import { ModalService } from './services/modal.service';
import { PaginationService } from './services/pagination.service';
import { PermissionsService } from './services/permissions.service';
import { QueryParamsService } from './services/queryParams.service';
import { ServerPaginationService } from './services/server-pagination.service';
import { ToDateStringService } from './services/to-date-string.service';
import { TranslationService } from './services/translation.service';
import { UpKeepService } from './services/up-keep.service';
import { UpdateDocumentModalComponent } from './components/update-document-modal/update-document-modal.component';
import { ConfigGuard } from './guards/config.guard';

@NgModule({
  declarations: [
    BreadcrumbComponent,
    FileInputComponent,
    FilterComponent,
    ModalComponent,
    LoaderComponent,
    PaginationComponent,
    ScheduleComponent,
    Schedulev2Component,
    TableComponent,
    TableServerComponent,
    TabComponent,
    TabsComponent,
    TooltipComponent,
    CardComponent,
    ClosableCardComponent,
    HeroIconComponent,
    ImageCropperComponent,
    SearchModalComponent,
    CommentsComponent,
    UpdateDocumentModalComponent,
    MultiSelectComponent,

    DefaultImageDirective,
    HasPermissionDirective,
    NAV_DROPDOWN_DIRECTIVES,
    ShowPasswordDirective,

    HighlightPipe,
    IntervalPipe,
    KeyPipe,
    OrderByPipe,
    SearchPipe,
    ValuesPipe,
    CurrencyPipe,
    SafeHtmlPipe,
  ],
  imports: [
    CommonModule,

    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    MatDialogModule,
    NgxPaginationModule,
    MomentModule,
    NgbModule,
    MatAutocompleteModule,
    MatSelectModule,
    MatMenuModule,
    SimpleNotificationsModule.forRoot(),
    TranslateModule.forChild(),
    UppyAngularDashboardModule,
  ],
  providers: [
    PaginationService,
    ToDateStringService,
    PermissionsService,
    LinesService,
    FeedService,
    ExportService,
    TranslationService,
    MomentModule,
    ServerPaginationService,
    UpKeepService,
    MatSelectModule,

    AuthGuard,
    ConfigGuard,
    CanDeactivateGuard,
    HomeGuard,
    OneNetworkGuard,
    PermissionGuard,

    LinesResolver,
    RolesResolver,
    TagsMailNetworkResolver,
    EmailsNetworkResolver,

    DateFormatPipe,
    CustomersService,
    CustomersFiltersService,
  ],
  exports: [
    BreadcrumbComponent,
    FileInputComponent,
    FilterComponent,
    ModalComponent,
    LoaderComponent,
    PaginationComponent,
    ScheduleComponent,
    Schedulev2Component,
    TableComponent,
    TableServerComponent,
    TabComponent,
    TabsComponent,
    TooltipComponent,
    CardComponent,
    ClosableCardComponent,
    HeroIconComponent,
    ImageCropperComponent,
    SearchModalComponent,
    CommentsComponent,
    UpdateDocumentModalComponent,
    MultiSelectComponent,

    DefaultImageDirective,
    HasPermissionDirective,
    NAV_DROPDOWN_DIRECTIVES,
    ShowPasswordDirective,

    HighlightPipe,
    IntervalPipe,
    KeyPipe,
    OrderByPipe,
    SearchPipe,
    ValuesPipe,
    CurrencyPipe,

    TranslateModule,
  ],
})
export class SharedModule {
  static forRoot(): ModuleWithProviders<any> {
    return {
      ngModule: SharedModule,
      providers: [
        ModalService,
        FilterService,
        CacheBustingService,
        ColorService,
        QueryParamsService,
        MatDialogModule,
      ],
    };
  }
}
