import { Injectable } from '@angular/core';
import { ModuleType } from '@app/models/betaOption';

@Injectable()
export class BetaOptionService {
  private localStorageBetasKey = 'AIRWEB_BETAS_OPTIN';
  private betasModules = [
    {
      name: 'analytics',
      defaultValue: false,
    },
    {
      name: 'authentication',
      defaultValue: true,
    },
  ];

  /**
   * Get Beta option
   * @param {ModuleType} module
   * @returns {boolean}
   */
  public getBetaOption(module: ModuleType): boolean {
    const localBataOptin = localStorage.getItem(this.localStorageBetasKey);
    if (!localBataOptin) {
      return this.betasModules.find((betaModule) => betaModule.name === module)?.defaultValue;
    }
    try {
      const betaOptin = JSON.parse(localBataOptin)[module];
      return betaOptin;
    } catch (error) {
      return this.betasModules.find((betaModule) => betaModule.name === module)?.defaultValue;
    }
  }

  /**
   * Set beta option
   * @param {ModuleType} module
   * @param {boolean} active
   * @returns {void}
   */
  public setBetaOption(module: ModuleType, active: boolean): void {
    const localBetasOption = JSON.parse(localStorage.getItem(this.localStorageBetasKey)) ?? {};
    let betasOption = {};
    for (const betaModule of this.betasModules) {
      betasOption[betaModule.name] = localBetasOption?.[betaModule.name] ?? betaModule.defaultValue;
      if (module === betaModule.name) {
        betasOption[betaModule.name] = active;
      }
    }
    localStorage.setItem(this.localStorageBetasKey, JSON.stringify(betasOption));
  }

  /**
   * Swtich beta option
   * @param {ModuleType} module
   * @returns {void}
   */
  public toggleBetaOption(module: ModuleType): void {
    const currentOptin = this.getBetaOption(module);
    this.setBetaOption(module, !currentOptin);
  }
}
