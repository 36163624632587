import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Address } from '@app/modules/shared/models/address';
import { GenericResponse } from '@app/modules/shared/models/generic-response';
import { Ticket } from '@app/modules/shared/models/ticket';
import { Voucher } from '@app/modules/shared/models/voucher';
import { AuthService } from '@app/modules/shared/services/auth.service';
import { FilterService } from '@app/modules/shared/services/filter.service';
import { SubscriptionDetails } from '@app/modules/subscriptions/models/subscription.interface';
import { CacheManager } from '@app/utils/cache-manager';
import { environment as env } from '@env/environment';
import { BehaviorSubject, firstValueFrom, Observable, of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { match } from 'ts-pattern';
import {
  AnonymizeResponse,
  Attribute,
  Contract,
  Customer,
  CustomerFusion,
  CustomerFusionConfiguration,
  CustomerOrders,
  CustomerOrderStatus,
  CustomerValidation,
  CustomerWalletEvent,
  CustomerWalletEventType,
  Document,
  SendResetPasswordEmailResponse,
  Session,
  SetCustomerAttributeResponse,
  TransfersHistory,
  UpdateInfoResponse,
} from '../models/customer';
import { CustomersFiltersService } from './filters.service';

export interface $$Comment {
  id: number;
  customer_id: number;
  author_id?: number;
  network_id?: number;
  text: string;
  created_at: string;
  deleted_at?: string;
  author?: unknown;
}

export interface $$Ticket {
  id: string;
  ticket_id: string;
  user_id: string;
  order_identifier?: string;
  order_id?: string;
  order_date?: string;
  product_id: string;
  start_date?: string;
  end_date?: string;
  label: string;
  status: string;
  network_id: string;
  dematerialized: string;
  category_id?: string;
  cms_user_id?: string;
  subscription_id?: number;
}

export interface $$Profile {
  customer_id: string;
  isParent: boolean;
  firstname: string;
  lastname: string;
  status: string;
  picture: string;
  active: boolean;
  anonymized_at?: any;
}

export interface $$Customer {
  customer_id: string;
  network_ids: number[];
  firstname: string;
  lastname: string;
  email: string;
  phone: string;
  address: {
    streetNumber: string;
    route: string;
    city: string;
    zipCode: string;
    country: string;
  };
  picture: string;
  date: string;
  birthday: string;
  active: string;
  lastActivityAt?: string | null;
  anonymized_at?: any;
  universal: boolean;
  provider: string;
  provider_uid: string;
  locale: string | null;
  verified_at?: string;
}

export interface $$GlobalAttribute {
  id: string;
  key: string;
  network_id: string;
  label: string;
  type: 'ENUM' | 'BOOLEAN';
  values: string[];
}

@Injectable()
export class CustomersService {
  private cache = new CacheManager('customers');

  constructor(
    private http: HttpClient,
    private authService: AuthService,
    private router: Router,
    private globalFilters: FilterService,
    private customerFilters: CustomersFiltersService
  ) {}

  public getCustomers(): Observable<Customer[]> {
    let version = this.cache.getVersion();

    return of([null]).pipe(
      switchMap(() =>
        this.http.get<GenericResponse<{ customers: Customer[] }>>(
          env.config.feedRoot + `Customer/list.json`,
          {
            params: {
              v: version,
              ...this.globalFilters.filtersWithID,
              ...this.customerFilters.getCleanValue(),
            },
          }
        )
      ),

      map((payload) => {
        // handle empty response as empty array
        if (!payload) return [];

        return payload.response.customers;
      })
    );
  }

  public deactivateCustomer(customerId: string): Promise<boolean> {
    const http$ = this.http
      .put<GenericResponse<{ success: boolean }>>(env.config.feedRoot + 'Customer/deactivate', {
        id: customerId,
      })
      .pipe(
        map(({ response }) => {
          return response.success;
        })
      );

    return firstValueFrom(http$);
  }

  public reactivateCustomer(customerId: string): Promise<boolean> {
    const http$ = this.http
      .put<GenericResponse<{ success: boolean }>>(env.config.feedRoot + 'Customer/reactivate', {
        id: customerId,
      })
      .pipe(
        map(({ response }) => {
          return response.success;
        })
      );

    return firstValueFrom(http$);
  }

  public $$getProfiles(customerId: string): Promise<$$Profile[]> {
    const http$ = this.http
      .get<GenericResponse<{ profiles: $$Profile[] }>>(env.config.feedRoot + `Customer/profiles`, {
        params: { customerId },
      })
      .pipe(map(({ response }) => response.profiles));

    return firstValueFrom(http$);
  }

  public getProfiles(customerId: string) {
    return firstValueFrom(
      this.http
        .get<GenericResponse<{ profiles: Customer[] }>>(
          env.config.feedRoot + `Customer/profiles.json`,
          {
            params: { customerId },
          }
        )
        .pipe(map(({ response }) => response.profiles))
    );
  }

  public $$getCustomer(customerId: string): Promise<$$Customer> {
    const http$ = this.http
      .get<GenericResponse<{ customer: $$Customer }>>(env.config.feedRoot + `Customer/details`, {
        params: { customerId },
      })
      .pipe(
        map(({ response }) => {
          if (response.customer === null) {
            this.router.navigate(['/403']);
          }

          return response.customer;
        })
      );

    return firstValueFrom(http$);
  }

  public getCustomerDetails(customerId: string) {
    const version = this.cache.getVersion();

    const http$ = this.http
      .get<GenericResponse<{ customer: Customer }>>(`${env.config.feedRoot}Customer/details.json`, {
        params: { version, customerId },
      })
      .pipe(
        map(({ response }) => {
          if (response.customer === null) {
            this.router.navigate(['/403']);
          }

          return response.customer;
        })
      );

    return firstValueFrom(http$);
  }

  public statusType = {
    PENDING: 'Validé',
    ACTIVE: 'A valider',
    REMAINING: 'Restant',
    EXPIRED: 'Expiré',
  };

  public $$getWallet(customerId: string): Promise<$$Ticket[]> {
    const http$ = this.http
      .get<GenericResponse<{ wallet: $$Ticket[] }>>(env.config.feedRoot + `Customer/wallet`, {
        params: { customerId },
      })
      .pipe(
        map(({ response }) => {
          return response.wallet;
        })
      );

    return firstValueFrom(http$);
  }

  public getWallet(customerId: string) {
    return firstValueFrom(
      this.http
        .get<GenericResponse<{ wallet: Ticket[] }>>(env.config.feedRoot + `Customer/wallet.json`, {
          params: { customerId },
        })
        .pipe(map(({ response }) => response.wallet))
    );
  }

  public $$getContracts(customerId: string): Promise<Contract[]> {
    const http$ = this.http
      .get<GenericResponse<{ contracts: Contract[] }>>(
        `${env.config.feedRoot}Wallet/contracts.json`,
        {
          params: { customerId },
        }
      )
      .pipe(
        map(({ response }) => {
          return response.contracts;
        })
      );

    return firstValueFrom(http$);
  }

  public getContracts(customerId: number) {
    return firstValueFrom(
      this.http
        .get<GenericResponse<{ contracts: Contract[] }>>(
          `${env.config.feedRoot}Wallet/contracts.json`,
          {
            params: { customerId },
          }
        )
        .pipe(
          map(({ response }) => {
            return response.contracts;
          })
        )
    );
  }

  public $$getVouchers(customerId: string): Promise<Voucher[]> {
    const http$ = this.http
      .get<GenericResponse<{ vouchers: Voucher[] }>>(env.config.feedRoot + `Customer/vouchers`, {
        params: { customerId },
      })
      .pipe(map(({ response }) => response.vouchers));

    return firstValueFrom(http$);
  }

  public $$getDocuments(customerId: string): Promise<Document[]> {
    const http$ = this.http
      .get<GenericResponse<{ documents: Document[] }>>(env.config.feedRoot + `Customer/documents`, {
        params: { customerId },
      })
      .pipe(
        map(({ response }) => {
          return response.documents;
        })
      );

    return firstValueFrom(http$);
  }

  public getCustomerAttributes(customerId: string): Promise<Attribute[]> {
    return firstValueFrom(
      this.http
        .get<GenericResponse<Attribute[]>>(env.config.feedRoot + `Customer/attributeValues`, {
          params: { customerId },
        })
        .pipe(map(({ response }) => response))
    );
  }

  public uploadImage(id: string, file: File) {
    let formData: FormData = new FormData();
    formData.append('media', file);
    formData.append('id', id);

    return this.http.post<any>(env.config.feedRoot + `Customer/updateAvatar`, formData).pipe(
      map(({ response: r }) => {
        this.cache.incrementVersion();
        return r;
      })
    );
  }

  public searchCustomer(query: string): Observable<Customer[]> {
    const searchUrl = new URL(`${env.config.feedRoot}Customer/search`);

    const searchParams = new URLSearchParams({
      ...this.globalFilters.filtersWithID,
      query,
      limit: '5',
    });
    searchUrl.search = searchParams.toString();

    return of([null]).pipe(
      switchMap(() =>
        this.http.get<GenericResponse<{ customers: Customer[] }>>(searchUrl.toString())
      ),

      map(({ response }) => {
        return response.customers;
      })
    );
  }

  public getSearchedCustomers(query: string): Observable<Customer[]> {
    const searchUrl = new URL(`${env.config.feedRoot}Customer/searchedCustomers`);

    const searchParams = new URLSearchParams({ ...this.globalFilters.filtersWithID, query });
    searchUrl.search = searchParams.toString();

    return of([null]).pipe(
      switchMap(() =>
        this.http.get<GenericResponse<{ customers: Customer[] }>>(searchUrl.toString())
      ),

      map(({ response }) => {
        return response.customers;
      })
    );
  }

  public $$getGlobalAttributes(): Promise<$$GlobalAttribute[]> {
    const http$ = this.http
      .get<GenericResponse<{ attributes: $$GlobalAttribute[] }>>(
        `${env.config.feedRoot}Customer/attributes`
      )
      .pipe(map(({ response }) => response.attributes));

    return firstValueFrom(http$);
  }

  public getAttributes(): Promise<any> {
    return firstValueFrom(
      this.http.get<GenericResponse>(`${env.config.feedRoot}Customer/attributes.json`)
    ).then(({ response }) => response.attributes);
  }

  public setCustomerAttribute(customerId, attributeId, value) {
    const body = {
      customerId,
      attributeId,
      value,
    };

    return firstValueFrom(
      this.http
        .post<SetCustomerAttributeResponse>(
          `${env.config.feedRoot}Customer/setAttributeValue.json`,
          body
        )
        .pipe(map(({ response }: any) => response))
    );
  }

  public $$getSubscriptions(
    parentProfileId: string,
    customerId: string
  ): Promise<SubscriptionDetails[]> {
    const http$ = this.http
      .get<GenericResponse<SubscriptionDetails[]>>(
        env.config.feedRoot + `Customer/getSubscriptions`,
        {
          params: { customerId: parentProfileId },
        }
      )
      .pipe(
        map(({ response }) => {
          return response.filter((subscription) => +subscription.recipient_user_id === +customerId);
        })
      );

    return firstValueFrom(http$);
  }

  // TODO: Type this
  public $$getMedias(customerId: string) {
    const http$ = this.http
      .get<any>(env.config.feedRoot + `Customer/medias`, {
        params: { customerId },
      })
      .pipe(map(({ response }) => response.medias));

    return firstValueFrom(http$);
  }

  public $$getComments(
    customerId: string,
    filters?: { networkId: string | number }
  ): Promise<$$Comment[]> {
    const http$ = this.http
      .get<GenericResponse<$$Comment[]>>(
        `${env.config.feedRoot}Customer/getCommentsAboutCustomer`,
        {
          params: { customerId, ...filters },
        }
      )
      .pipe(
        map(({ response }) => (response.errorMessage ? [] : response)),
        map((comments) =>
          filters?.networkId !== undefined
            ? comments.filter((comment) => +comment.network_id === +filters.networkId)
            : comments
        )
      );

    return firstValueFrom(http$);
  }

  public getCustomerComments(customerId: string) {
    const value$ = new BehaviorSubject(null);

    const http$ = this.http.get<GenericResponse>(
      `${env.config.feedRoot}Customer/getCommentsAboutCustomer.json`,
      {
        params: { customerId },
      }
    );

    const fetch = (filters: { networkId?: string } = {}) => {
      firstValueFrom(
        http$.pipe(
          // If we have an error, just return an empty array..
          map(({ response }) => (response.errorMessage ? [] : response)),
          map((comments) =>
            comments && filters?.networkId !== undefined
              ? comments.filter((comment) => +comment.network_id === +filters.networkId)
              : comments
          )
        )
      ).then((comments) => value$.next(comments));
    };

    return { value$, fetch } as const;
  }

  public addCustomerComment(customerId: string, body: { networkId: string; text: string }) {
    return this.http
      .post<GenericResponse>(`${env.config.feedRoot}Customer/addCommentAboutCustomer.json`, {
        customerId,
        ...body,
      })
      .pipe(map(({ response }) => response));
  }

  public async editCustomerComment(
    commentId: number,
    customerId: string,
    body: { networkId: string; text: string }
  ) {
    const deleteRequest = await this.deleteCustomerComment(String(commentId));
    if (!deleteRequest) return deleteRequest;

    return firstValueFrom(this.addCustomerComment(customerId, body));
  }

  public deleteCustomerComment(commentId: string) {
    const http$ = this.http
      .delete<GenericResponse>(`${env.config.feedRoot}Customer/removeCommentAboutCustomer`, {
        params: { commentId },
      })
      .pipe(map(({ response }) => response));

    return firstValueFrom(http$);
  }

  public anonymizeCustomer(customerId: string) {
    return firstValueFrom(
      this.http
        .delete<AnonymizeResponse>(`${env.config.feedRoot}Customer/anonymize.json`, {
          params: { customerId },
        })
        .pipe(
          map((response) => {
            return response;
          })
        )
    );
  }

  public sendResetPasswordEmail(customerId: string) {
    return firstValueFrom(
      this.http
        .post<SendResetPasswordEmailResponse>(`${env.config.feedRoot}Customer/resetPassword.json`, {
          customerId,
        })
        .pipe(
          map(({ response }) => {
            return response;
          })
        )
    );
  }

  public updateInfo(
    customerId: string,
    params: {
      firstname?: string;
      lastname?: string;
      birthday?: string;
      email?: string;
      phone?: string;
      address?: Address;
    }
  ) {
    if (!params) return;

    return firstValueFrom(
      this.http
        .post<UpdateInfoResponse>(`${env.config.feedRoot}Customer/updateInfo.json`, {
          customerId,
          ...params,
        })
        .pipe(
          map(({ response }) => {
            return response;
          })
        )
    );
  }

  public linkNetwork(customerId: number, networkId: number) {
    return firstValueFrom(
      this.http
        .post<GenericResponse<{ success: boolean; networkIds: number[] }>>(
          `${env.config.feedRoot}Customer/linkNetwork.json`,
          {
            customerId,
            networkId,
          }
        )
        .pipe(
          map(({ response }) => {
            return response;
          })
        )
    );
  }

  public unlinkNetwork(customerId: number, networkId: number) {
    return firstValueFrom(
      this.http
        .delete<GenericResponse<{ success: boolean; networkIds: number[] }>>(
          `${env.config.feedRoot}Customer/unlinkNetwork.json`,
          {
            params: { customerId, networkId },
          }
        )
        .pipe(
          map(({ response }) => {
            return response;
          })
        )
    );
  }

  public disableContract(contractId: string) {
    return firstValueFrom(
      this.http
        .put<GenericResponse<{ contract: Contract }>>(
          `${env.config.feedRoot}Wallet/updateContract.json`,
          {
            contractId,
            blockedAt: new Date().toISOString(),
          }
        )
        .pipe(
          map(({ response }) => {
            return response;
          })
        )
    );
  }

  public reactivateContract(contractId: string) {
    return firstValueFrom(
      this.http
        .put<GenericResponse<{ contract: Contract }>>(
          `${env.config.feedRoot}Wallet/updateContract.json`,
          {
            contractId,
            blockedAt: null,
          }
        )
        .pipe(
          map(({ response }) => {
            return response;
          })
        )
    );
  }

  public sendVerificationMail(customerId: string) {
    return firstValueFrom(
      this.http
        .post<GenericResponse<{ success: boolean }>>(
          `${env.config.feedRoot}Customer/sendVerificationEmail`,
          {
            customerId: customerId,
          }
        )
        .pipe(
          map(({ response }) => {
            return response;
          })
        )
    );
  }

  public releaseSessions(customerId: string) {
    return firstValueFrom(
      this.http
        .post<GenericResponse>(`${env.config.feedRoot}Customer/releaseSessions`, {
          customerId,
        })
        .pipe(
          map(({ response }) => {
            return response;
          })
        )
    );
  }

  public getHistory(customerId: string): Promise<TransfersHistory> {
    const http = this.http
      .get<GenericResponse>(`${env.config.feedRoot}Customer/transfers`, {
        params: {
          customerId,
          status: 'DONE',
        },
      })
      .pipe(map(({ response }) => response));
    return firstValueFrom(http);
  }

  public getCustomerOrders(customerId: string, status?: CustomerOrderStatus) {
    let params: { customerId: string; status?: CustomerOrderStatus } = {
      customerId,
    };

    if (status) {
      params = {
        status,
        ...params,
      };
    }

    const http = this.http
      .get<GenericResponse<{ orders: CustomerOrders[] }>>(`${env.config.feedRoot}Customer/orders`, {
        params,
      })
      .pipe(
        map(({ response }) => {
          return response?.orders;
        })
      );

    return firstValueFrom(http);
  }

  public getCustomerSessions(customerId: string) {
    return firstValueFrom(
      this.http
        .get<GenericResponse<{ sessions: Session[] }>>(`${env.config.feedRoot}Customer/sessions`, {
          params: { id: customerId },
        })
        .pipe(
          map(({ response }) => {
            return response.sessions;
          })
        )
    );
  }

  public getFusionList(
    customerId: string,
    query: string
  ): Promise<GenericResponse<{ customers: CustomerFusion[] }>> {
    const http$ = this.http
      .get<GenericResponse<{ customers: CustomerFusion[] }>>(
        env.config.feedRoot + `Customer/searchFusionable`,
        {
          params: { id: customerId, query },
        }
      )
      .pipe(map((response) => response));

    return firstValueFrom(http$);
  }

  public fusionCustomers(
    recipientCustomerId: number,
    formerCustomerId: number,
    configuration: CustomerFusionConfiguration
  ): Promise<boolean> {
    const http$ = this.http
      .post<GenericResponse<{ success: boolean }>>(`${env.config.feedRoot}Customer/fusion`, {
        recipientCustomerId: recipientCustomerId,
        formerCustomerId: formerCustomerId,
        configuration: JSON.stringify(configuration),
      })
      .pipe(
        catchError((error) => {
          console.error('Error on Customer/fusion flux', error);
          return of({ response: { success: false } });
        }),
        map(({ response }) => response?.success || false)
      );

    return firstValueFrom(http$);
  }

  public getValidations(customerId: number): Promise<CustomerValidation[]> {
    const http$ = this.http
      .get<GenericResponse<{ validations: CustomerValidation[] }>>(
        `${env.config.feedRoot}Customer/validations`,
        { params: { customerId } }
      )
      .pipe(
        catchError((error) => {
          console.error('Error on Customer/validations', error);
          return of({ response: { validations: [] } });
        }),
        map(({ response }) => response.validations)
      );

    return firstValueFrom(http$);
  }

  public getWalletEvents(
    customerId: number,
    type?: CustomerWalletEventType[]
  ): Promise<CustomerWalletEvent[]> {
    const params = { customerId };

    if (type) {
      params['type'] = type.join(',');
    }

    const http$ = this.http
      .get<GenericResponse<{ events: CustomerWalletEvent[] }>>(
        `${env.config.feedRoot}Customer/walletEvents`,
        { params }
      )
      .pipe(
        catchError((error) => {
          console.error('Error on Customer/walletEvents', error);
          return of({ response: { events: [] } });
        }),
        map(({ response }) => response.events)
      );

    return firstValueFrom(http$);
  }

  public checkAccount(
    customerId: string
  ): Promise<{ success: boolean; error?: { code: string; message: string } }> {
    const http$ = this.http
      .post<{ response: { success: boolean; error?: { code: string; message: string } } }>(
        `${env.config.feedRoot}Customer/verify`,
        { customerId }
      )
      .pipe(
        catchError((error) => {
          console.error('Error on Customer/verify flux', error);
          const message = match(error?.error?.code)
            .with(
              'CUSTOMER_VERIFICATION_CUSTOMER_INACTIVE',
              () => `pages.customer_details.account_check_validation_error_inactive`
            )
            .with(
              'CUSTOMER_VERIFICATION_CUSTOMER_ACCOUNT_ALREADY_VERIFIED',
              () => `pages.customer_details.account_check_already_verified`
            )
            .with(
              'CUSTOMER_VERIFICATION_CUSTOMER_ACCOUNT_INVALID',
              () => `pages.customer_details.account_check_account_invalid`
            )
            .otherwise(() => `otherslabels.unknown_error`);
          return of({ response: { success: false, error: { code: error?.error?.code, message } } });
        }),
        map(({ response }) => {
          return {
            success: response?.success || false,
            error: response?.error || null,
          };
        })
      );

    return firstValueFrom(http$);
  }
}
