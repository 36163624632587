import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { AuthService } from '../services/auth.service';

@Injectable()
export class HomeGuard implements CanActivate {
  private homes = [
    {
      permission: 'ACCESS_DASHBOARD',
      route: '/dashboard-ng',
    },
    {
      permission: 'ACCESS_ORDERS',
      route: '/orders',
    },
    {
      permission: 'ACCESS_SCHOOL_ORDERS',
      route: '/school',
    },
    {
      permission: 'ACCESS_COLORS',
      route: '/colors',
    },
    {
      permission: 'ACCESS_SUBMISSIONS',
      route: '/submissions',
    },
    {
      permission: 'ACCESS_REPORTS',
      route: '/reports',
    },
    {
      permission: 'TAPNPROTECT',
      route: '/tap-and-protect/dashboard',
    },
    {
      permission: 'TAPNPROTECT',
      route: '/tap-and-protect/dashboard',
    },
    {
      permission: 'EDIT_ITEM_PRODUCT_RESTRICTED_CREATOR',
      route: '/shop/products',
    },
    {
      permission: 'EDIT_ITEM_PRODUCT_RESTRICTED_MODIFICATOR',
      route: '/shop/products',
    },
    {
      permission: 'EDIT_ITEM_PRODUCT_RESTRICTED_EDITOR',
      route: '/shop/products',
    },
  ];

  constructor(private router: Router, private authService: AuthService) {}

  canActivate(): boolean {
    const path = this.homes.find((home) => this.authService.permissions.includes(home.permission));

    if (path) {
      this.router
        .navigate([path.route], { queryParamsHandling: 'merge' })
        .catch((e) => console.error(e));
    } else {
      this.authService.logout();
      this.router.navigate(['/login']);
    }

    return false;
  }
}
