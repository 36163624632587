import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AppLayoutComponent } from './app-layout/app-layout.component';
import { AuthGuard } from './modules/shared/guards/auth.guard';
import { HomeGuard } from './modules/shared/guards/home.guard';
import { PermissionGuard } from './modules/shared/guards/permission.guard';
import { AuthService } from './modules/shared/services/auth.service';
import { BadgeService } from './modules/shared/services/badge.service';
import { ConfigGuard } from './modules/shared/guards/config.guard';

const routes: Routes = [
  {
    path: 'login',
    loadChildren: () => import('./modules/auth/auth.module').then((m) => m.AuthModule),
  },
  {
    path: 'maintenance',
    canLoad: [AuthGuard],
    loadChildren: () =>
      import('./modules/maintenance/maintenance.module').then((m) => m.MaintenanceModule),
  },
  {
    path: '',
    canLoad: [AuthGuard],
    canActivateChild: [PermissionGuard],
    component: AppLayoutComponent,
    children: [
      {
        path: '',
        pathMatch: 'full',
        canActivate: [HomeGuard],
        canLoad: [AuthGuard],
        loadChildren: () =>
          import('./modules/forbidden/forbidden.module').then((m) => m.ForbiddenModule),
      },
      {
        path: 'account-management',
        canLoad: [AuthGuard],
        loadChildren: () =>
          import('./modules/account-management/account-management.module').then(
            (m) => m.AccountManagementModule
          ),
      },
      {
        path: 'documents',

        canLoad: [AuthGuard],
        loadChildren: () =>
          import('./modules/document/document.module').then((m) => m.DocumentModule),
      },
      {
        path: 'graphic-documents',

        canLoad: [AuthGuard],
        loadChildren: () =>
          import('./modules/graphic-document/graphic-document.module').then(
            (m) => m.GraphicDocumentModule
          ),
      },
      {
        path: 'dashboard',

        canLoad: [AuthGuard],
        canActivate: [ConfigGuard],
        canActivateChild: [ConfigGuard],
        loadChildren: () =>
          import('./modules/dashboard/dashboard.module').then((m) => m.DashboardModule),
        data: {
          features: ['dashboard'],
        },
      },
      {
        path: 'dashboard-next',

        canLoad: [AuthGuard],
        canActivate: [ConfigGuard],
        canActivateChild: [ConfigGuard],
        loadChildren: () =>
          import('./modules/dashboard-next/dashboard.module').then((m) => m.DashboardModule),
        data: {
          features: ['dashboard'],
        },
      },
      {
        path: 'dashboard-ng',

        canLoad: [AuthGuard],
        canActivate: [ConfigGuard],
        canActivateChild: [ConfigGuard],
        loadChildren: () =>
          import('./modules/dashboard-ng/dashboard-ng.module').then((m) => m.DashboardNgModule),
        data: {
          features: ['dashboard'],
        },
      },
      {
        path: 'orders',
        canLoad: [AuthGuard],
        loadChildren: () => import('./modules/orders/orders.module').then((m) => m.OrdersModule),
      },
      {
        path: 'subscriptions',
        canLoad: [AuthGuard],
        loadChildren: () =>
          import('./modules/subscriptions/subscriptions.module').then((m) => m.SubscriptionsModule),
      },
      {
        path: 'customers',
        canLoad: [AuthGuard],
        loadChildren: () =>
          import('./modules/customers/customers.module').then((m) => m.CustomersModule),
      },
      {
        path: 'validations',

        canLoad: [AuthGuard],
        loadChildren: () =>
          import('./modules/validations/validations.module').then((m) => m.ValidationsModule),
      },
      {
        path: 'ticket',

        canLoad: [AuthGuard],
        loadChildren: () => import('./modules/ticket/ticket.module').then((m) => m.TicketModule),
      },
      {
        path: 'geolocation',

        canLoad: [AuthGuard],
        loadChildren: () =>
          import('./modules/geolocation/geolocation.module').then((m) => m.GeolocationModule),
      },
      {
        path: 'controls',

        canLoad: [AuthGuard],
        loadChildren: () =>
          import('./modules/controls/controls.module').then((m) => m.ControlsModule),
      },
      {
        path: 'colors',

        canLoad: [AuthGuard],
        loadChildren: () => import('./modules/colors/colors.module').then((m) => m.ColorsModule),
      },
      {
        path: 'salesterms',

        canLoad: [AuthGuard],
        loadChildren: () =>
          import('./modules/salesterms/salesterms.module').then((m) => m.SalesTermsModule),
      },
      {
        path: 'legal-notice',

        canLoad: [AuthGuard],
        loadChildren: () =>
          import('./modules/legal-notice/legal-notice.module').then((m) => m.LegalNoticeModule),
      },
      {
        path: 'privacy-policy',

        canLoad: [AuthGuard],
        loadChildren: () =>
          import('./modules/privacy-policy/privacy-policy.module').then(
            (m) => m.PrivacyPolicyModule
          ),
      },
      {
        path: 'contact',

        canLoad: [AuthGuard],
        loadChildren: () => import('./modules/contact/contact.module').then((m) => m.ContactModule),
      },
      {
        path: 'reports',

        canLoad: [AuthGuard],
        loadChildren: () =>
          import('./modules/lemonway-reports/lemonway-reports.module').then(
            (m) => m.LemonwayReportsModule
          ),
      },
      {
        path: 'payment',

        canLoad: [AuthGuard],
        loadChildren: () => import('./modules/payment/payment.module').then((m) => m.PaymentModule),
      },
      {
        path: 'fleet',

        canLoad: [AuthGuard],
        loadChildren: () => import('./modules/fleet/fleet.module').then((m) => m.FleetModule),
      },
      {
        path: 'providers',

        canLoad: [AuthGuard],
        loadChildren: () =>
          import('./modules/providers/providers.module').then((m) => m.ProvidersModule),
      },
      {
        path: 'graphics',

        canLoad: [AuthGuard],
        loadChildren: () =>
          import('./modules/graphics/graphics.module').then((m) => m.GraphicsModule),
      },
      {
        path: 'users',

        canLoad: [AuthGuard],
        loadChildren: () => import('./modules/users/users.module').then((m) => m.UsersModule),
      },
      {
        path: 'networks',

        canLoad: [AuthGuard],
        loadChildren: () => import('./modules/network/network.module').then((m) => m.NetworkModule),
      },
      {
        path: 'clients',

        canLoad: [AuthGuard],
        loadChildren: () => import('./modules/clients/clients.module').then((m) => m.ClientsModule),
      },
      {
        path: 'gtfs',

        canLoad: [AuthGuard],
        loadChildren: () => import('./modules/gtfs/gtfs.module').then((m) => m.GtfsModule),
      },
      {
        path: 'shop',

        canLoad: [AuthGuard],
        loadChildren: () => import('./modules/shop/shop.module').then((m) => m.ShopModule),
      },
      {
        path: 'vehicle',

        canLoad: [AuthGuard],
        loadChildren: () => import('./modules/vehicle/vehicle.module').then((m) => m.VehicleModule),
      },
      {
        path: 'trips',

        canLoad: [AuthGuard],
        loadChildren: () => import('./modules/trips/trips.module').then((m) => m.TripsModule),
      },
      {
        path: 'booking',

        canLoad: [AuthGuard],
        loadChildren: () => import('./modules/booking/booking.module').then((m) => m.BookingModule),
      },
      {
        path: 'logs',

        canLoad: [AuthGuard],
        loadChildren: () => import('./modules/audit/audit.module').then((m) => m.AuditModule),
      },
      {
        path: 'submissions',

        canLoad: [AuthGuard],
        loadChildren: () =>
          import('./modules/submissions/submissions.module').then((m) => m.SubmissionsModule),
      },
      {
        path: 'school',

        canLoad: [AuthGuard],
        loadChildren: () => import('./modules/school/school.module').then((m) => m.SchoolModule),
      },
      {
        path: 'fields',
        canLoad: [AuthGuard],
        loadChildren: () => import('./modules/fields/fields.module').then((m) => m.FieldsModule),
      },
      {
        path: 'tap-and-protect',
        canLoad: [AuthGuard],
        loadChildren: () =>
          import('./modules/tap-and-protect/tap-and-protect.module').then(
            (m) => m.TapAndProtectModule
          ),
      },
      {
        path: 'validators',
        canLoad: [AuthGuard],
        loadChildren: () =>
          import('./modules/validators/validators.module').then((m) => m.ValidatorsModule),
      },
      {
        path: 'provisioning',
        canLoad: [AuthGuard],
        loadChildren: () =>
          import('./modules/provisioning/provisioning.module').then((m) => m.ProvisioningModule),
      },
      {
        path: 'platform',
        canLoad: [AuthGuard],
        loadChildren: () =>
          import('./modules/platform/platform.module').then((m) => m.PlatformModule),
      },
      {
        path: 'changelog',
        canLoad: [AuthGuard],
        loadChildren: () =>
          import('./modules/changelog/changelog.module').then((m) => m.ChangelogModule),
      },
      {
        path: 'agencies',
        canLoad: [AuthGuard],
        loadChildren: () =>
          import('./modules/agencies/agencies.module').then((m) => m.AgenciesModule),
      },
      {
        path: 'maintenance-mode',
        canLoad: [AuthGuard],
        loadChildren: () =>
          import('./modules/maintenance-mode/maintenance-mode.module').then(
            (m) => m.MaintenanceModeModule
          ),
      },
      {
        path: '403',
        canLoad: [AuthGuard],
        loadChildren: () =>
          import('./modules/forbidden/forbidden.module').then((m) => m.ForbiddenModule),
      },
      {
        path: '404',
        canLoad: [AuthGuard],
        loadChildren: () =>
          import('./modules/notfound/notfound.module').then((m) => m.NotfoundModule),
      },
      {
        path: '500',
        canLoad: [AuthGuard],
        loadChildren: () =>
          import('./modules/internal-server-error/internal-server-error.module').then(
            (m) => m.InternalServerErrorModule
          ),
      },
      {
        path: '**',
        canLoad: [AuthGuard],
        redirectTo: '/404',
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
  providers: [AuthService, BadgeService],
})
export class AppRoutingModule {}
